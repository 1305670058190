exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coffeeride-js": () => import("./../../../src/pages/coffeeride.js" /* webpackChunkName: "component---src-pages-coffeeride-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-naszeultra-js": () => import("./../../../src/pages/naszeultra.js" /* webpackChunkName: "component---src-pages-naszeultra-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-category-list-js": () => import("./../../../src/templates/category-list.js" /* webpackChunkName: "component---src-templates-category-list-js" */),
  "component---src-templates-race-list-js": () => import("./../../../src/templates/race-list.js" /* webpackChunkName: "component---src-templates-race-list-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */),
  "component---src-templates-single-category-js": () => import("./../../../src/templates/single-category.js" /* webpackChunkName: "component---src-templates-single-category-js" */),
  "component---src-templates-single-coffee-js": () => import("./../../../src/templates/single-coffee.js" /* webpackChunkName: "component---src-templates-single-coffee-js" */),
  "component---src-templates-single-race-js": () => import("./../../../src/templates/single-race.js" /* webpackChunkName: "component---src-templates-single-race-js" */)
}

